/** Right column **/

.display-image-bottle{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(min-width:992px){
    .wine-details{
        padding-top:0px !important;
    }
    .display-image-bottle{
        position:fixed;
        margin-left:-200px;

    }
    .wine-bottle{
        height:650px;
        margin-top:-100px;
        margin-left: -140px;
    }

    .wine-not-default{
        max-height: 650px;
        margin-top: 0px;
        margin-left: -10px;
    }

    .wine-details-gold:hover {
        color:$theme-color-gold !important;
        a:hover{
            color:$theme-color-gold !important;
        }

    }

    a.test:hover{
        color:$theme-color-gold !important;
    }

    .c-bg-transparent-force{
        background: none !important;
    }
}
@media(max-width:991px){
    .wine-bottle{
        height:500px;
        //margin-left:5%;
    }

    .wine-not-default{
        max-height: 500px;
       // margin-left:5%;
    }

    .wine-details{
        padding-top:0px !important;
    }
}

@media(max-width:760px){
    .wine-not-default{
        max-height: 500px;
        //margin-left:0%;
    }
}
@media(max-width:600px){
    .wine-not-default{
        max-height: 500px;
       // margin-left:-8%;
    }
}
@media(max-width:450px){
    .wine-not-default{
        max-height: 500px;
        //margin-left:-25%;
    }
}
@media(max-width:400px){
    .wine-not-default{
        max-height: 500px;
       // margin-left:-20%;
    }
}

@media(max-width:350px){
    .wine-not-default{
        max-height: 500px;
        //margin-left:-25%;
    }
}

@media(max-width:300px){
    .wine-not-default{
        max-height: 500px;
        //margin-left:-30%;
    }
}

.c-content-box-custom{
    padding-top:50px !important;
}

/** Left Column **/

.action-icone{
    max-height:60px;
}

.table-center{
    text-align:center;
}

.quality-icone{
    max-height:10px;
}

.table-quality{
    vertical-align: middle !important;
}

/** Contest **/
.contest-logo{
    width:100%;
    max-width:100%;
    height:auto;
}

.contest-year{
    text-align: center;
    color:#fff;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}

.wine-content-description{
    ul{
        li{
            list-style: none;
        }
    }
}
.wine-contests{
    background-color:#2F353B;
    padding: 20px 25px 20px 25px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}


/** Content **/

.wine-content-header-red{
    //background-color: lighten(#36020E, 10%) !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 20px 25px 20px 25px;
}

.wine-content-body{
    padding: 20px 25px 20px 25px;
    margin-top: 15px;

}

.wine-content{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color:#FFF;
    //box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.content-title{
    color:$theme-color;
    border-bottom: 1px solid $theme-color-gold;
    margin-bottom: 15px;
    font-size:15pt;
}

.content-content{
    margin-bottom:50px;
    //font-size: 12pt;
}

.content-description{
    //color:#fff;
    //font-size: 12pt;
}

.localisation-img{
    width:100%;
    height: auto;
}

// REd wine

.wine-type-red{
    font-size:15pt;
    color:lighten(#68061D,10%) !important;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}

.wine-name-red{
    font-size: 40pt;
    //margin-bottom: 10px;
    color: #FFF;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}
.wine-locality-red{
    font-size: 20pt;
    //margin-bottom: 10px;
    color: #FFF;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //        0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}

.wine-details-red{
    background-color: #36020E;
}

.wine-content-red{
    .content-title{
        color:$theme-color-gold;
    }

    .content-title-header{
        color:$theme-color-gold;
    }
}

//white wine
.wine-type-white{
    font-size:15pt;
    color:lighten($theme-color-gold,10%) !important;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}

.wine-name-white{
    font-size: 40pt;
    //margin-bottom: 10px;
    color: #FFF;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}
.wine-locality-white{
    font-size: 20pt;
    //margin-bottom: 10px;
    color: #FFF;
    //text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    //         0px 8px 13px rgba(0,0,0,0.1),
    //         0px 18px 23px rgba(0,0,0,0.1);
}

.wine-details-white{
    background-color: #483D21;
}

.wine-content-white{
    .content-title{
        color:$theme-color-gold;
        border-bottom: 1px solid $theme-color-gold;
    }

    /*.content-title-header{
        color:#483e21;
        //border-bottom: 1px solid #fff;
    }*/
}

.wine-content-conservation{
    .content-title{
        color:$theme-color-gold;
        border-bottom: 1px solid $theme-color-gold;
    }

    /*.content-title-header{
        color:#483e21;
        //border-bottom: 1px solid #fff;
    }*/
}

.wine-content-header-white{
    //background-color: lighten($theme-color-gold, 10%) !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 20px 25px 20px 25px;
}
