.owl-dot.active{
    background-color: $theme-color-gold !important;
}

.c-theme-on-hover:hover{
    color:$theme-color-gold;
}

.c-content-list-1{
    li::before{
        background-color: $theme-color !important;
    }
}

.c-card{
    p{
        line-height:24px !important;
    }
}

.c-justify{
    text-align: justify;
}

.c-custom-philosophy{
    padding:35px !important;
    background-color: rgba(35, 31, 30, 0.7) !important;
}

.c-custom-history{
    padding:35px !important;
}

.c-custom-philosophy-left{
    padding-bottom: 8px !important;
}


@media(min-width:991px){
    .c-theme-philosphy{

        background-size:100%;background-repeat: no-repeat;
    }
    .c-photo-history{
        position: absolute !important;
        right:0;
        top:80%;
    }
}

.c-theme-interalar{
    background-color: $theme-color;
}

.c-bg-opacity-002{
    background-color: rgba(255, 255, 255, 0.02);
}

ul.custom {
  list-style: none;
  padding: 0;
  margin: 0;
}

li.custom {
  padding-left: 1em;
  text-indent: -0.3em;
}

li.custom::before {
  content: "• ";
  color: $theme-color-gold; /* or whatever color you prefer */
}
