.c-content-overlay-news{
    height:260px;
}

.c-content-blog-post-card-1:hover{
    box-shadow: 0 5px 5px rgba(0,0,0,0.10), 0 4px 3px rgba(0,0,0,0.22) !important;
    transform: translate3d(0, -3px, 0) !important;
    transition: all 150ms ease-in-out !important;
}
.test{
    display:block;
    width:100%;
    height:100%;
}

.c-tags{
    float: left !important;
}

.c-title-news-details{
    border-bottom: 1px solid $theme-color-gold;
}

.c-body-news{
    height:250px !important;
    overflow-y:auto !important;
    overflow-x: hidden !important;
}

.img-news{
    min-height:265px;
}

.c-content-pagination{
    .c-active{
        span{
            border-color: $theme-color !important;;
            background: $theme-color !important;
        }
    }
}
