.cbp-filter-counter{
    background-color: $theme-color !important;
}

.c-theme-ul-bg{
    li{
        background: $theme-color !important;
    }
}

a {
    color:$theme-color;
}

a:hover{
    color: darken($theme-color, 15%);
}

.c-theme{
    li:before{
        color: $theme-color !important;
    }
}

.no-wines{
    background-color: #fff;
    padding: 50px;

    .item-no-wines{
        margin-top:35px;
        margin-left:10px;
    }
}

.c-bg-opacity-custom {
	background-color: rgba(255, 255, 255, 0.7);
}

.c-custom-padding{
    padding:35px !important;
}

.wine-title-etiquette{
    // position:relative;
    //transform: translate(0%, -120px);
    text-align:center;
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right:0;
    top:45%;
}

.white-wine{
    color: rgb(74, 156, 94) !important;
}

.red-wine{
    color: rgb(190, 43, 49) !important;
}

.c-content-box-custom{
    padding-top:50px !important;
}

.c-font-adapt{
    font-size:13px;
}

.c-font-adapt-subtitle{
    font-size:11px;
}
.c-font-adapt-subtitle2{
    font-size:11px;
    font-style: italic;
}

@media(max-width:1195px){
    .c-content-box-custom{
        padding-top:20% !important;
    }
}


@media(max-width:991px){
    .wine-title-etiquette{
        // position:relative;
        //transform: translate(0%, -500%);
        text-align:center;
    }
    .c-font-adapt{
        font-size:15px;
    }

    .c-font-adapt-subtitle{
        font-size:13px;
    }

    .c-font-adapt-subtitle2{
        font-size:13px;
    }

    .c-content-box-custom{
        padding-top:30% !important;
    }
}

@media(max-width:535px){
    .wine-title-etiquette{
        // position:relative;
        // transform: translate(0%, -130px);
        text-align:center;
    }
    .c-font-adapt{
        font-size:18px;
    }
    .c-font-adapt-subtitle{
        font-size:15px;
    }
    .c-font-adapt-subtitle2{
        font-size:15px;
    }
    .c-subheader-wine{
        height:400px !important;
    }
}

.wine-etiquette{
    box-shadow: 0 5px 5px rgba(0,0,0,0.10), 0 4px 3px rgba(0,0,0,0.22) !important;
}

.bg-wines{
    position:relative;
}

.bg-wines{
    background-image: url('/images/images/accueil/domaine/domaine2.jpg');
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    min-height: 100%;
}

.cbp-item:hover{
    transform: translate3d(0, -3px, 0) !important;
}

.cbp-wrapper-outer{
    overflow:visible !important;
}

@media(min-width:991px){
    .img-sellers-top{
        max-height:none;
        position:absolute;
        top:0;
        left:-35%
    }
    .img-sellers-bot{
        max-height:none;
        position:absolute;
        top:100%;
        right:50%;
        height:550px
    }

    .c-subheader-wine{
        height:300px;
    }

    .pre-header{
        width:60% !important;
    }

    .pre-header-2{
        width:75% !important;
    }
}

@media(max-width:991px){
    .img-sellers-top{
        display:none;
    }
    .c-subheader-wine{
        height:350px;
    }
    .pre-header{
        width:100%;
    }
    .pre-header-2{
        width:100% ;
    }
}

@media(max-width:535px){
    .c-subheader-wine{
        height:400px !important;
    }

    .c-card-custom{
        padding:6px !important;
    }
}
