.c-navbar{
    background-color: #FFF !important;
    opacity: 1;
}

.c-link-small {
    padding: 41px 5px 39px 5px !important;
}


.c-page-on-scroll{
    .c-layout-header{
        .c-navbar{
            opacity:1 !important;
            .container{
                .c-navbar-wrapper{
                    .c-brand{
                        margin: 3px 0px 0px 0px !important;
                    }
                }
            }
        }

    }

}

.menu-round{
    border-radius: 10px !important;
    padding: 2px 3px 2px 6px !important;
    border-color: rgba(47, 53, 59, 0.1);

}
.menu-round:hover{
    background-color: inherit !important;
    color: inherit !important;
    border-color: inherit !important;

}

.dropdown-menu{
    background: rgba(47, 53, 59, 0.9) !important;
    min-width: 95px;
    text-align: center;
    right:30px !important;
}

.c-menu-custom{
    right: 34% !important;
    li{
        ul{
            background: rgba(47, 53, 59, 1) !important;
            opacity: 1 !important;
        }
    }
}


.c-onepage-link.active > a{
    background-color:  $theme-color-gold !important;
}
.c-onepage-link  > a:hover{
    background-color:  $theme-color-gold !important;
}
.c-onepage-link.c-active > a{
    background-color:  $theme-color-gold !important;
}

@media (min-width: 992px){
    /*.c-layout-header-fixed .c-layout-page {
        margin-top: 105px !important;
    }*/
    .c-brand{
        margin: 20px 0px 0px 0px !important;
    }
    .c-menu-custom{
        right: 34% !important;
        li{
            ul{
                background: rgba(47, 53, 59, 1) !important;
                opacity: 1 !important;
            }
        }

    }

    .c-btn-header {
        margin: 28px 15px 33px 15px !important;
    }

/*
    .c-link-nav{
        padding: 55px 15px 15px 15px !important;
    }

    .c-layout-header-onepage{
        margin:0px !important;
        background: none !important
    }*/


    .separator{
        //margin-top:54px !important;
        background-color: rgba(47, 53, 59, 0.1);
        height:30px;
        width:1px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .transparent{
        background-color: #36020E !important;
        opacity: 1 !important;
        .c-theme-nav li.c-active, .c-theme-nav li.c-active > a:not(.btn, .test){
            color: $theme-color-gold !important;
        }

        .c-theme-nav li:hover >a:not(.btn), .c-theme-nav li.c-active:hover > a:not(.btn, .test){
            color: $theme-color-gold !important;
        }

    }

    .transparent-white{
        background-color: #483D21 !important;
        opacity: 1 !important;
        .transparent-lang{
            a:hover{
                background-color:  $theme-color-gold !important;
            }

            a:focus{
                background-color:  $theme-color-gold !important;
            }
        }
        .c-theme-nav li.c-active, .c-theme-nav li.c-active > a:not(.btn, .test){
            color: $theme-color-gold !important;
        }

        .c-theme-nav li:hover >a:not(.btn), .c-theme-nav li.c-active:hover > a:not(.btn, .test){
            color: $theme-color-gold !important;
        }

    }

    .transparent-lang{
        a:hover{
            background-color: #36020E !important;
        }

        a:focus{
            background-color: #36020E !important;
        }
    }

    .c-page-on-scroll .c-layout-header.c-layout-header-4{
        border-bottom: none !important;
    }

    .nav-transparent{
        li{
            a{
                color : #fff !important;
            }

            .c-btn-border-opacity-04{
                border-color: rgba(255,255,255,0.4) !important;
            }
        }
    }

    .c-subtitle{
        padding: 5px 0px !important;
    }

    .c-page-title{
        h3{
            margin-top: 20px !important;
        }
    }
}
/*.c-btn{
    margin: 45px 15px 0px 15px !important;
    color:#3f444a !important;
}*/

/*.c-cart-menu-title{
    padding: 10px !important;
}
.c-cart-menu-content{
    a{
        width:100%;
        display:block;
    }
}

.c-cart-menu-items{
    padding-left: 5px !important;
    li{
        padding:0 !important;
        padding-left:20px;
    }
}
.c-cart-menu{
    width:150px !important;
    z-index:5000;
}*/

@media (max-width: 991px){

    .c-brand{
        margin: 20px 0px 0px 0px !important;
    }
    .c-logo{
        width:80%;
        display:block;
        margin-right:0;
    }

    .c-hor-nav-toggler{
        top:-45px !important;
    }

    .c-btn{
        margin:0 !important;
        border:none !important;
        color:#fff;
    }

    .c-btn:focus,.c-btn:hover{
        background-color: inherit !important;
    }

    .c-cart-menu{
        top:310% !important;
        width:100% !important;
        text-align: center !important;

    }
    .c-cart-menu-items{
        padding-left: 0px !important;
        li{
            padding:0 !important;
            padding-left:0px;
        }
    }

    .c-cart-menu-content{
        padding-left:0px !important;
        a{
            width:100%;
            display:block;
        }
    }

    .c-cart-menu-title{
        display: none !important;
    }

}

/*.c-cart-menu{
    top:90% !important;
}*/

.c-theme-nav li.c-active, .c-theme-nav li.c-active > a:not(.btn, .test){
    color: $theme-color-gold !important;
}

.c-theme-nav li:hover >a:not(.btn), .c-theme-nav li.c-active:hover > a:not(.btn, .test){
    color: $theme-color-gold !important;
}

.c-hor-nav-toggler{
    margin-right:20px;
}
