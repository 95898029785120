body{
    font-family: 'Open Sans', sans-serif !important;
}


.c-theme-font-title{
    font-family: 'Prata',serif !important;
}

.c-theme-font-subtitle{
    font-family: 'Open Sans Condensed',sans-serif !important
}

.c-theme-color-gold{
    color:$theme-color-gold !important;
}
.c-theme-color-red{
    color:$theme-color !important;
}

.c-theme-border-bottom-gold{
    border-bottom: 1px solid $theme-color-gold;
}

.c-font-95{
    font-size: 95px;
}

.c-font-80{
    font-size:80px;
}

.c-layout-page{
    margin-top:100px !important;
}

@media(max-width:991px){
    /*.c-theme-subtitle{
        margin-top:15%;
    }*/

    .tp-rightarrow{
        display:none !important;
    }
    .tp-leftarrow{
        display:none !important;
    }

    .c-theme-subtitle-text{
        //margin-top:5%;
        //display: none !important;
        margin-left: 0;
        margin-right: 0;
    }
    .slider-subtitle-left, .slider-subtitle-right, .c-theme-btn-slider{
        display: none !important;
    }

    .c-layout-page{
        margin-top:170px !important;
    }

    .c-theme-subtitle-slider{
        display: block !important;
        margin-top:15px !important;
    }
}

@media (min-width: 992px){
    .img-responsive{
        min-height: 235px;
        max-height: 385px;
        width: auto;
    }

    .c-theme-subtitle{
        //text-shadow: 0px 3px 3px rgba(0,0,0,0.6),
        //         0px 7px 13px rgba(0,0,0,0.3),
        //         0px 17px 23px rgba(0,0,0,0.3);
        letter-spacing: normal;

    }

    .c-theme-subtitle-text{
        float:left;
        margin-left:150px;
        margin-right:100px;
        margin-top:15px;
        padding-left:15px;
    }

    .footer-img{
        width:40px !important;
        height:40px !important;
        border-radius: 0px !important;
    }

    .slider-subtitle-left{
        border-top: 1px solid white;
        height:3px;
        width:50px;
        float:left;
        margin-top:45px;
        //margin-right:20px;
        margin-left:40px
    }
    .slider-subtitle-right{
        border-top: 1px solid white;
        height:3px;
        width:50px;
        float:left;
        margin-top:45px;
        //margin-left:20px;
        margin-right:20px;
    }

    .slider-content{
        width:1500px;
    }

    .c-content-feature-5 .c-text{
        width:45% !important;
    }

    .c-bordered-image{
        border: 2px solid $theme-color-gold;
        padding:5px;
    }


    .c-img-right-40{
        right:40px;
    }
}

.c-img-bottle{
    height:400px;
    width:auto;
}

.c-main-title-circle{
    background-color:rgba(0,0,0,0) !important;
}

.c-theme-btn{
    border-width:2px !important;
    border-color:$theme-color !important;
    border-radius: 1px;
}

.c-theme-btn:hover{
    border-color:darken($theme-color,10%) !important;
    background-color: darken($theme-color,10%) !important;
    color:#fff !important;
}


.c-theme-btn-gold{
    border-width:2px !important;
    border-color:$theme-color-gold !important;
    border-radius: 1px;
}

.c-theme-btn-gold:hover{
    border-color:darken($theme-color-gold,10%) !important;
    background-color: darken($theme-color-gold,10%) !important;
    color:#fff !important;
}


.c-theme-btn-transparent{
    background-color: Transparent !important;
}

.c-theme-btn-full, .c-bg-color{
    background-color: $theme-color !important;
}

.c-bg-color-gold{
    background-color: $theme-color-gold !important;
}

//HOME - DOMAINE
.c-bg-custom-1{
    background-color: $background-color-1;
    .container{
        min-height: 450px !important;
    }
}

.panel{
    .panel-heading{
        .panel-title{
            a{
                background-color: $theme-color-gold !important;
                text-decoration: none !important;
            }
            a.collapsed{
                background-color:white !important;
                color:#3f444a !important;
                text-decoration: none !important;
            }
            a:hover, a.collapsed:hover{
                color:#fff !important;
                background-color: $theme-color-gold !important;
                text-decoration: none !important;
            }
            a:focus{
                text-decoration: none !important;
            }
        }
    }
    .panel-body{
        background-color: $theme-color-gold !important;
        border-top:none !important;
    }

}



//HOME - NEWS
@media (min-width: 992px){
    .c-arrow-1::after{
        border-left-color: $theme-color !important;
    }
    .c-arrow-2::after{
        border-left-color:  #2F343A !important;
    }
    .c-arrow-3::after{
        border-right-color: #497693 !important;
    }
    .c-arrow-4::after{
        border-right-color: #AF9A63 !important;
    }
}
@media(max-width:991px){
    .c-arrow-1::after{
        border-top-color: $theme-color !important;
    }
    .c-arrow-2::after{
        border-top-color:  #2F343A !important;
    }
    .c-arrow-3::after{
        border-bottom-color: #497693 !important;
    }
    .c-arrow-4::after{
        border-bottom-color: #AF9A63 !important;
    }

    .img-bottle{
        position: absolute !important;
        top:unset !important;
        bottom: -45px !important;
        height:70% !important;
        right: 85px !important;
    }

    .c-theme-subtitle{
        font-size: 4.1vw !important
    }
}
@media(max-width:700px){
    .c-layout-page{
        margin-top:110px !important;
    }
}

@media(max-width:350px){
    .c-layout-page{
        margin-top:100px !important;
    }
    .img-bottle{
        height:50% !important;
    }
}

.c-news-content-1{
    background-color: $theme-color !important;
}
.c-news-content-2{
    background-color: #2F343A !important;
}
.c-news-content-3{
    background-color: #497693 !important;
}
.c-news-content-4{
    background-color: #AF9A63 !important;
}
.c-news-no-padding-bottom{
    padding-bottom:0px !important;
}

.btn-newsletters{
    border-radius: 0px !important;
}

.email-input:focus{
    background-color: #fff;
}
