@media(min-width:992px){
    .c-logo-footer{
        width:400px;
        height: auto;
        margin-top:-35px;
    }
}

@media(max-width:991px){
    .c-logo-footer{
        height:250px;
        width: auto;
        text-align: center;
        display: block;
        margin: auto;
    }
}



.c-line-footer{
    width:100% !important;
    height:1px !important;
    margin-bottom: 15px !important;
    background-color: $theme-color-gold !important;
}

.btn-no-border{
    border: none !important;
}

.footer-border-top{
    border-top:1px solid #333A40;
}

.c-links > li >a:hover{
    color: $theme-color-gold !important;
}

.c-address{
    li{
        a{
            color:#636b6f !important;
            text-decoration: underline !important;
        }
        a:hover{
            color: #636b6f !important;
        }
        a:focus{
            color: #636b6f !important;
        }
        a:visited{
            color: #636b6f !important;
        }
    }
}
