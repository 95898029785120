.c-theme{
    li:hover{
        i{
            background-color: $theme-color !important;
        }
    }
}

.contact-link:focus{
    color:$theme-color !important;
}

.card-link{
    display:block;
    height:100%;
    width:100%;
}

.c-theme.form-control:focus{
    border-color: $theme-color !important;
}
